.formatMedia {
    padding: 10px;
    margin-bottom: 15px;
}

.formatImage {
    margin-right: 5px;
}

.formatImage:hover {
    opacity: 0.95;
    filter: brightness(95%);
}