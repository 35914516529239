* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
}

body, html {
    font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
    width: 100%;
}